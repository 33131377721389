import * as React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "../layouts/Layout";

import Login from "../pages/authentication/LogIn";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import Dashboard from "../components/dashboard/Dashboard";

import User from "../components/users/User";
import UserAdd from "../components/users/UserAdd";
import UserEdit from "../components/users/UserEdit";
import UserView from "../components/users/UserView";

import Artist from "../components/artist/Artist";
import ArtistView from "../components/artist/ArtistView";

import Support from "../components/support/Support";
import SupportEdit from "../components/support/SupportEdit";

import Advertisement from "../pages/Advertisement";

import Privacy from "../components/privacy&policy/PrivacyPolicy"; 
import PrivacyEdit from "../components/privacy&policy/PrivacyPolicyEdit";
import Terms from "../components/terms&conditions/TermsConditions";
import Business from "../components/business/Business";
import AuthCheck from "./AuthCheck";

import Channel from "../pages/Channel";
import ChannelAdd from "../components/channel/ChannelAdd";
import ChannelView from "../components/channel/ChannelView";
import PlaylistView from "../components/channel/playlist/PlaylistView";
import ShowView from "../components/channel/show/ShowView";

import Category from "../components/category/Category";
import CategoryAdd from "../components/category/CategoryAdd";
import CategoryEdit from "../components/category/CategoryEdit";

import Language from "../components/language/Language";
import LanguageAdd from "../components/language/LanguageAdd";
import LanguageEdit from "../components/language/LanguageEdit";
import ChannelList from "../components/channel/ChannelList";
import ImageCompress from "../pages/ImageCompress";

const router = createBrowserRouter([
    // {
    //     path: "/",
    //     element: <Login />
    // },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/resetPassword",
        element: <ForgotPassword />
    },
    {
        path: "/",
        element: <AuthCheck component={Layout} />,
        children: [ 
            {
                path: "",
                element: <Navigate to="dashboard" />
            },
            {
                path: "dashboard",
                element: <AuthCheck component={Dashboard} />
            },
            // {
            //     path: "dashboard",
            //     element: <AuthCheck component={Dashboard} />
            // },
            {
                path: "user",
                element: <AuthCheck component={User} />
            },
            {
                path: "user/create",
                element: <AuthCheck component={UserAdd} />
            },
            {
                path: "user/edit/:id",
                element: <AuthCheck component={UserEdit} />
            },
            {
                path: "user/view",
                element: <AuthCheck component={UserView} />
            },
            {
                path: "artist",
                element: <AuthCheck component={Artist} />
            },
            {
                path: "artist/view/:id",
                element: <AuthCheck component={ArtistView} />
            },
            {
                path: "channel",
                element: <AuthCheck component={Channel} />,
                children: [
                    {
                        path: "",
                        element: <Navigate to="list" />
                    },
                    {
                        path: "list",
                        element: <AuthCheck component={ChannelList} />
                    },
                    {
                        path: "create",
                        element: <AuthCheck component={ChannelAdd} />
                    },
                    {
                        path: "view/:channelId",
                        element: <AuthCheck component={ChannelView} />
                    },
                    {
                        path: ":channelId/playlistView/:playlistId",
                        element: <AuthCheck component={PlaylistView} />
                    },
                    {
                        path: ":channelId/ShowView/:showId",
                        element: <AuthCheck component={ShowView} />
                    }
                    
                ]
            },
            
            {
                path: "category",
                element: <AuthCheck component={Category} />
            },
            {
                path: "category/create",
                element: <AuthCheck component={CategoryAdd} />
            },{
                path: "category/edit/:id",
                element: <AuthCheck component={CategoryEdit} />
            },
            {
                path: "language",
                element: <AuthCheck component={Language} />
            },
            {
                path: "language/create",
                element: <AuthCheck component={LanguageAdd} />
            },
            {
                path: "language/edit/:id",
                element: <AuthCheck component={LanguageEdit} />
            },
            {
                path: "advertisement",
                element: <AuthCheck component={Advertisement} />
            },
            {
                path: "support",
                element: <AuthCheck component={Support} />
            },
            {
                path: "support/edit/:id",
                element: <AuthCheck component={SupportEdit} />
            },
            {
                path: "privacy",
                element: <AuthCheck component={Privacy} />
            },
            {
                path: "privacy/edit",
                element: <AuthCheck component={PrivacyEdit} />
            },
            {
                path: "terms",
                element: <AuthCheck component={Terms} />
            },
            {
                path: "business",
                element: <AuthCheck component={Business} />
            }
        ],
    },
    {
        path: "*",
        element: <h1>This page is not found</h1>
    }
]);

export default router;