import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/artist/artist.module.scss";
import artistProfile from "../../assets/images/Admin/User-profile.png";
import { IoMdClose } from "react-icons/io";
import { MdFilterListAlt } from "react-icons/md";
import { BsSortDown } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import { useDispatch } from 'react-redux';
import ContentLoaderGif from "../../assets/images/contentLoader.gif";

const Artist = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const navigate = useNavigate();

  const [filterDate, setFilterDate] = useState({
    from: '',
    to: ''
  });
  const [statusFilter, setStatusFilter] = useState('');
  const [approveFilter, setApproveFilter] = useState('');
  const [artistFilter, setArtistFilter] = useState({
    from: '',
    to: '',
    search: '',
    sorting: 'z_to_a',
    // page: "1",
    // limit: "10"
  });

  const dateStatusApply = () => {
    setArtistFilter({ ...artistFilter, from: filterDate.from, to: filterDate.to, status: statusFilter, approve: approveFilter });
    setIsOpen(false);
  };

  const handleClear = () => {
    setFilterDate({ from: '', to: '' });
    setStatusFilter('');
    setApproveFilter('');
  };

  const handleReset = () => {
    setFilterDate({ from: '', to: '' });
    setStatusFilter('');
    setApproveFilter('');
    setArtistFilter({ ...artistFilter, from: "", to: "", status: "" });
    setIsOpen(false);
  };

  const [artistData, setArtistData] = useState([]);
  const [contentLoader, setContentLoader] = useState('true');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {

    const token = localStorage.getItem("token");
    // const userData = JSON.parse(localStorage.getItem("user_data"));
    // let dataa = convertDuration(4.2356);
    // console.log(dataa);

    const getArtistData = async () => {
      try {
        const getArtistList = await axios.get(`${baseUrl}admin/artist/list`, {
          params: {
            from: artistFilter.from,
            to: artistFilter.to,
            status: statusFilter,
            search: artistFilter.search,
            sort: artistFilter.sorting,
            approved_status: approveFilter
            // page: artistFilter.page,
            // limit: artistFilter.limit
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getArtistList;
        console.log(result);
        if (result.data.status === 200) {
          setContentLoader(result.data.data.artist.length === 0 ? true : false);
          setArtistData(result.data.data.artist);
          // setPagination({
          //   ...pagination,
          //   currentPage: result.data.data.pagination.currentPage,
          //   totalPage: result.data.data.pagination.totalPage,
          // });
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getArtistData();
  }, [artistFilter, refresh]);

  return (
    <div className={`${Styles.artist}`}>

      <div className={`${Styles.artist_heading}`}>
        <h2>Artist</h2>
      </div>

      <div className={`${Styles.artist_nav}`}>

        {/* Filter And Search Data */}
        <div className={`${Styles.filter_search_btn}`}>
          <div className={`${Styles.filter_sort}`}>
            <div className={`${Styles.filter}`}>
              <button onClick={() => { setIsOpen(!isOpen); if (isSortOpen) setIsSortOpen(false); }}><MdFilterListAlt /> Filter</button>
              {isOpen && (
                <div className={`${Styles.filter_dropdown}`}>
                  <div className={`${Styles.heading}`}>
                    <p>Filter</p>
                    <IoMdClose onClick={() => setIsOpen(!isOpen)} />
                  </div>

                  {/* <div className={`${Styles.selectDate}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Date</p>
                        <p onClick={handleClear}>Clear</p>
                      </div>
                      <div className={`${Styles.filter_date}`}>
                        <div className={`${Styles.from}`}>
                          <label htmlFor="from">From:</label>
                          <input type="date" id='from' placeholder='00-00-0000' value={filterDate.from} onChange={(e) => setFilterDate({ ...filterDate, from: e.target.value })} />
                        </div>
                        <div className={`${Styles.to}`}>
                          <label htmlFor="to">To:</label>
                          <input type="date" id='to' placeholder='00-00-0000' value={filterDate.to} onChange={(e) => setFilterDate({ ...filterDate, to: e.target.value })} />
                        </div>
                      </div>
                    </div> */}

                  <div className={`${Styles.selectStatus}`}>
                    <div className={`${Styles.heading_clear}`}>
                      <p>Select Account Status</p>
                      <p onClick={() => setApproveFilter('')}>Clear</p>
                    </div>
                    <select name="approveFilter" id="approveFilter" value={approveFilter} onChange={(e) => setApproveFilter(e.target.value)}>
                      <option value="">**** Select Account Status ****</option>
                      <option value="1">Approved</option>
                      <option value="0">Approval Pending</option>
                    </select>
                  </div>

                  <div className={`${Styles.selectStatus}`}>
                    <div className={`${Styles.heading_clear}`}>
                      <p>Select Status</p>
                      <p onClick={() => setStatusFilter('')}>Clear</p>
                    </div>
                    <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                      <option value="">**** Select Status ****</option>
                      <option value="1">Active</option>
                      <option value="0">Deactive</option>
                    </select>
                  </div>

                  <div className={`${Styles.buttons}`}>
                    <button onClick={handleReset}>Reset</button>
                    <button onClick={dateStatusApply}>Apply Now</button>
                  </div>
                </div>
              )}
            </div>

            <div className={`${Styles.sort}`}>
              <button onClick={() => { setIsSortOpen(!isSortOpen); if (isOpen) setIsOpen(false); }}><BsSortDown /> Sort</button>
              {isSortOpen && (
                <div className={`${Styles.sort_dropdown}`}>
                  <p onClick={() => { setArtistFilter({ ...artistFilter, sorting: "a_to_z" }); setIsSortOpen(false); }}><span>A to Z</span><span>{(artistFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                  <p onClick={() => { setArtistFilter({ ...artistFilter, sorting: "z_to_a" }); setIsSortOpen(false); }}><span>Z to A</span><span>{(artistFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                  {/* <p onClick={() => {setSortingValue("latestDate"); setIsSortOpen(false);}}><span>Latest Date</span><span>{(sortingValue === "latestDate") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setSortingValue("oldestDate"); setIsSortOpen(false);}}><span>Oldest Date</span><span>{(sortingValue === "oldestDate") ? <BiCheck /> : ""}</span></p> */}
                </div>
              )}
            </div>
          </div>
          <div className={`${Styles.search}`}>
            <input type="text" placeholder="Search" onInput={(e) => setArtistFilter({ ...artistFilter, search: e.target.value })} />
          </div>
        </div>

        {/* Artist List */}
        <div className={`${Styles.artist_list}`}>
          {
            artistData.length === 0 && setArtistFilter === false &&
            <div className={`${Styles.dataNotFound}`}>
              {
                contentLoader &&
                <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
              }
              {
                !contentLoader &&
                <p>Artist not found</p>
              }
            </div>
          }
          {
            artistData.map((data) => (
              <div className={`${Styles.artist_description}`} onClick={() => navigate(`/artist/view/${data.id}`)}>
                <div className={`${Styles.artist_profile}`}>
                  <img src={data.image_url === null ? artistProfile : data.image_url} alt="artist profile picture" />
                </div>
                {/* <h4>{data.artist_name}</h4> */}
                <h4>{data.artist_name.charAt(0).toUpperCase() + data.artist_name.slice(1)}</h4>
                {/* <p>Artist</p> */}
              </div>
            ))
          }
          {setArtistFilter !== '' && contentLoader ? (
            <div className={`${Styles.dataNotFound}`}>
              <p>Artist not found</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Artist
