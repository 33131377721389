import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/channel/channelView.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { FaGripLinesVertical } from "react-icons/fa6";
import { IoRemoveOutline } from "react-icons/io5";
import { FaUserCheck, FaUserXmark } from "react-icons/fa6";
import { MdDelete, MdEdit } from "react-icons/md";
import { LuPlusCircle } from "react-icons/lu";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { baseUrl } from "../../utils/constant";
import axios from "axios";
import { convertDuration } from "../../utils/convertFunctions";
import { FaUsers } from "react-icons/fa";
import { LuClock7 } from "react-icons/lu";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import { IoCloseCircleOutline } from "react-icons/io5";
import ContentLoaderGif from "../../assets/images/contentLoader.gif";

const ChannelView = () => {
  const navigate = useNavigate();
  const { channelId } = useParams();
  const [channelModule, setChannelModule] = useState("track");

  const [editTrack, setEditTrack] = useState(false);
  const [deleteChannelTrack, setDeleteChannelTrack] = useState(false);

  const [addTrackModal, setAddTrackModal] = useState(false);
  const [addPlaylistModal, setAddPlaylistModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);

  const [channelTracks, setChannelTracks] = useState([]);
  const [channelPlaylist, setChannelPlaylist] = useState([]);
  const [channelShow, setChannelShow] = useState([]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [deleteChannelDrop, setDeleteChannelDrop] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [contentLoader, setContentLoader] = useState('true');

  const [artistIds, setArtistIds] = useState([]);
  const [artistMetaData, setArtistMetaData] = useState([]);

  const [trackSearch, setTrackSearch] = useState([]);
  const [playlistSearch, setPlaylistSearch] = useState([]);
  const [showSearch, setShowSearch] = useState([]);

  const [dropDownChannelManage, setDropDownChannelManage] = useState({
    id: 0,
    open: false
  });

  const [channelDetails, setChannelDetails] = useState({
    title: "",
    image: "",
    status: "",
    totalTrack: 0,
    totalPlaylist: 0
  });
  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);
  const [allArtistList, setAllArtistList] = useState([]);

  const [addTrackData, setAddTrackData] = useState({
    title: "",
    description: "",
    language_id: "",
    category_id: "",
    status: 1,
    search: "",
    track_file: null,
    trackFileName: "",
    coverImage: null,
    coverImageName: "",
    duration: 0
  });

  const [addPlaylistData, setAddPlaylistData] = useState({
    title: "",
    description: "",
    status: 1,
    language_id: "",
    category_id: "",
    release_date: "",
    playlistCoverImage: null,
    playlistCoverImageName: "",
    playlistProfileImage: null,
    playlistProfileImageName: ""
  });

  const [addShowData, setAddShowData] = useState({
    title: "",
    description: "",
    status: 1,
    language_id: "",
    type: "audio",
    show_type: "special",
    shows_image: null,
    shows_image_name: "",
    shows_audio_video: null,
    shows_audio_video_name: "",
    duration: 0
  });

  const [editTrackData, setEditTrackData] = useState({
    title: "",
    description: "",
    status: 1,
    language_id: "",
    category_id: "",
    track_duration: "",
    cover_image: null,
    cover_image_name: "",
    music_track: null,
    music_track_name: ""
  });

  const handleTrackFile = (e) => {
    const file = e.target.files[0];
    console.log(file);
    console.log("outside");
    if (file && (file.type === 'audio/mpeg')) {
      console.log("inside");
      const audio = new Audio(URL.createObjectURL(file));
      audio.addEventListener("loadedmetadata", () => {
        setAddTrackData({ ...addTrackData, track_file: file, duration: audio.duration, trackFileName: file.name });
      });
    } else {
      setAddTrackData({ ...addTrackData, track_file: "", duration: 0, trackFileName: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid audio file (mp3)." }));
    }
  };

  const handleCoverPictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setAddTrackData({ ...addTrackData, coverImage: file, coverImageName: file.name });
    } else {
      setAddTrackData({ ...addTrackData, coverImage: null, coverImageName: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)." }));
    }
  };

  const handlePlaylistCoverPictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setAddPlaylistData({ ...addPlaylistData, playlistCoverImage: file, playlistCoverImageName: file.name });
    } else {
      setAddPlaylistData({ ...addPlaylistData, playlistCoverImage: null, playlistCoverImageName: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)." }));
    }
  };

  const handlePlaylistProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setAddPlaylistData({ ...addPlaylistData, playlistProfileImage: file, playlistProfileImageName: file.name });
    } else {
      setAddPlaylistData({ ...addPlaylistData, playlistProfileImage: null, playlistProfileImageName: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)." }));
    }
  };

  const handleShowProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setAddShowData({ ...addShowData, shows_image: file, shows_image_name: file.name });
    } else {
      setAddShowData({ ...addShowData, shows_image: null, shows_image_name: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)." }));
    }
  };

  const handleShowFile = (e) => {
    const file = e.target.files[0];
    console.log(file);
    console.log("outside");
    if (file && (file.type === 'audio/mpeg')) {
      console.log("inside");
      const audio = new Audio(URL.createObjectURL(file));
      audio.addEventListener("loadedmetadata", () => {
        setAddShowData({ ...addShowData, shows_audio_video: file, duration: audio.duration, shows_audio_video_name: file.name });
      });
    } else {
      setAddShowData({ ...addShowData, shows_audio_video: "", duration: 0, shows_audio_video_name: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid audio file (mp3)." }));
    }
  };

  const changeChannelStatus = async (changeStatus) => {
    try {
      const token = localStorage.getItem("token");
      const changeApproveStatus = await axios({
        method: 'POST',
        url: `${baseUrl}admin/channel-update-status`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          "id": channelId,
          "status": changeStatus
        }
      });

      const result = changeApproveStatus;
      if (result.status === 200) {
        setRefresh(refresh + 1);
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: "Channel status change successfully." }));
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Failed to change channel status." }));
      }
    } catch (error) {
      console.log(error);
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "An error occurred while changing the channel status." }));
    }

  };

  useEffect(() => {
    const getAllRequiredData = async () => {

      const token = localStorage.getItem("token");
      try {
        const getChannelData = await axios.get(`${baseUrl}admin/channel/get/${channelId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        console.log("Result : ", getChannelData);
        if (getChannelData.status === 200) {
          setChannelDetails({
            title: getChannelData.data.data.channel.channel_name,
            image: getChannelData.data.data.channel.channel_profile_image,
            status: getChannelData.data.data.channel.status === 1 ? "Publish" : "Unpublish",
            totalTrack: getChannelData.data.data.channel.channel_tracks.length,
            totalPlaylist: getChannelData.data.data.channel.channel_playlists.length
          });
          // setChannelPlaylist(getChannelData.data.data.channel.channel_playlists);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        }
      }

      try {
        const getCategoryData = await axios.get(`${baseUrl}admin/category/list?page=1&limit=5000&form&to&status=1&search&sort=desc`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        if (getCategoryData.status === 200) {
          setCategory(getCategoryData.data.data.getCategory);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        }
      }


      try {
        const getLanguageData = await axios.get(`${baseUrl}admin/language/list?status=1`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        if (getLanguageData.status === 200) {
          setLanguage(getLanguageData.data.data.languages);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        }
      }

    }
    getAllRequiredData();
  }, [refresh]);


  useEffect(() => {
    const token = localStorage.getItem("token");
    const getData = async () => {
      try {
        const getChannelData = await axios.get(`${baseUrl}admin/get-channel-playlists/${channelId}?search=${playlistSearch}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        console.log("Result : ", getChannelData);
        if (getChannelData.status === 200) {
          setChannelPlaylist(getChannelData.data.data);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        }
      }
    }
    getData();
  }, [playlistSearch, refresh]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const getData = async () => {
      try {
        const getShowData = await axios.get(`${baseUrl}admin/list-channel-shows/${channelId}?search=${showSearch}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        console.log("Result of Show : ", getShowData);
        if (getShowData.status === 200) {
          setChannelShow(getShowData.data.data.channel_shows);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        }
      }
    }
    getData();
  }, [showSearch, refresh]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const getData = async () => {
      try {
        const getTrackData = await axios.get(`${baseUrl}admin/channel/list-tracks/${channelId}?search=${trackSearch}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        console.log("Result : ", getTrackData);
        if (getTrackData.status === 200) {
          setChannelTracks(getTrackData.data.data);
          setContentLoader(getTrackData.data.data.length === 0 ? true : false);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        }
      }
    }
    getData();
  }, [trackSearch, refresh]);

  const handleArtistId = async (id, name) => {
    if (artistIds.includes(id)) {
      const filter = artistIds.filter((data) => {
        return data != id;
      });

      const metaDataFilter = artistMetaData.filter((data) => {
        return data.id != id;
      })
      setArtistIds(filter);
      setArtistMetaData(metaDataFilter);
    }

    if (!artistIds.includes(id)) {
      setArtistIds([...artistIds, id]);
      setArtistMetaData([...artistMetaData, { id: id, name: name }]);
    }
  };

  console.log("Ids : ", artistIds);
  console.log("Artist Meta Data : ", artistMetaData);

  const handleAddNormalTrackSubmit = async (e) => {

    e.preventDefault();
    setLoading(true);

    if (addTrackData.title === "" || addTrackData.description === "" || addTrackData.language_id === "" || addTrackData.category_id === "" || addTrackData.status === "" || addTrackData.duration === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please fill all the fields." }));
      setLoading(false);
      return;
    }

    if (addTrackData.track_file === "" || addTrackData.trackFileName === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Track file is required." }));
      setLoading(false);
      return;
    }

    if (addTrackData.coverImage === "" || addTrackData.coverImageName === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Cover picture is required." }));
      setLoading(false);
      return;
    }

    const formDataSet = new FormData();
    formDataSet.append('channel_id', channelId);
    formDataSet.append('title', addTrackData.title);
    formDataSet.append('description', addTrackData.description);
    formDataSet.append('track_duration', addTrackData.duration);
    formDataSet.append('language_id', addTrackData.language_id);
    formDataSet.append('category_id', addTrackData.category_id);
    formDataSet.append('status', addTrackData.status);
    formDataSet.append('music_track', addTrackData.track_file);
    formDataSet.append('cover_image', addTrackData.coverImage);
    formDataSet.append('artist_ids', artistIds);

    const token = localStorage.getItem("token");

    try {

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/channel/add-tracks`,
        data: formDataSet,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if (result.data.status === 200) {
        setLoading(false);
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setAddTrackModal(false);
        setRefresh(refresh + 1);
        setArtistIds("");
        setAddTrackData({
          title: "",
          description: "",
          language_id: 1,
          category_id: "",
          status: 1,
          search: "",
          track_file: null,
          trackFileName: "",
          coverImage: null,
          coverImageName: "",
          duration: 0
        });
      } else {
        console.log("Track Add Failed");
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  };

  const handleAddNormalPlaylistSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (addPlaylistData.title === "" || addPlaylistData.description === "" || addPlaylistData.language_id === "" || addPlaylistData.category_id === "" || addPlaylistData.status === "" || addPlaylistData.release_date === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please fill all the fields." }));
      setLoading(false);
      return;
    }

    if (addPlaylistData.playlistProfileImage === "" || addPlaylistData.playlistProfileImageName === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Profile picture is required." }));
      setLoading(false);
      return;
    }

    if (addPlaylistData.playlistCoverImage === "" || addPlaylistData.playlistCoverImageName === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Cover picture is required." }));
      setLoading(false);
      return;
    }

    const formDataSet = new FormData();
    formDataSet.append('channel_id', channelId);
    formDataSet.append('title', addPlaylistData.title);
    formDataSet.append('description', addPlaylistData.description);
    formDataSet.append('release_date', addPlaylistData.release_date);
    formDataSet.append('language_id', addPlaylistData.language_id);
    formDataSet.append('category_id', addPlaylistData.category_id);
    formDataSet.append('status', addPlaylistData.status);
    formDataSet.append('playlist_profile_image', addPlaylistData.playlistProfileImage);
    formDataSet.append('playlist_cover_image', addPlaylistData.playlistCoverImage);

    const token = localStorage.getItem("token");

    try {

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/create-channel-playlist`,
        data: formDataSet,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if (result.data.status === 200) {
        setLoading(false);
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setAddPlaylistModal(false);
        setRefresh(refresh + 1);
        setAddPlaylistData({
          title: "",
          description: "",
          language_id: 1,
          category_id: 1,
          status: 1,
          playlistProfileImage: null,
          playlistProfileImageName: "",
          playlistCoverImage: null,
          playlistCoverImageName: ""
        });
      } else {
        console.log("Playlist Add Failed");
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  };

  const handleAddNormalShowSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (addShowData.title === "" || addShowData.description === "" || addShowData.language_id === "" || addShowData.show_type === "" || addShowData.status === "" || addShowData.type === "" || addShowData.duration === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please fill all the fields." }));
      setLoading(false);
      return;
    }

    if (addShowData.shows_image === "" || addShowData.shows_image_name === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Show picture is required." }));
      setLoading(false);
      return;
    }

    if (addShowData.shows_audio_video === "" || addShowData.shows_audio_video_name === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Track file is required." }));
      setLoading(false);
      return;
    }

    const formDataSet = new FormData();
    formDataSet.append('channel_id', channelId);
    formDataSet.append('title', addShowData.title);
    formDataSet.append('description', addShowData.description);
    formDataSet.append('language_id', addShowData.language_id);
    formDataSet.append('status', addShowData.status);
    formDataSet.append('type', "audio");
    formDataSet.append('track_duration', addShowData.duration);
    formDataSet.append('show_type', "special")
    formDataSet.append('shows_image', addShowData.shows_image);
    formDataSet.append('shows_audio_video', addShowData.shows_audio_video);

    const token = localStorage.getItem("token");

    try {

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/create-channel-shows`,
        data: formDataSet,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if (result.data.status === 200) {
        setLoading(false);
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setAddShowModal(false);
        setRefresh(refresh + 1);
        setAddShowData({
          title: "",
          description: "",
          status: 1,
          language_id: "",
          type: "audio",
          show_type: "special",
          shows_image: null,
          shows_image_name: "",
          shows_audio_video: null,
          shows_audio_video_name: "",
          duration: 0
        });
      } else {
        console.log("Show Add Failed");
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  };

  const artistSearch = async () => {
    const token = localStorage.getItem("token");
    try {
      const getArtistData = await axios.get(`${baseUrl}admin/artist/list?status=1&sort=z_to_a&limit=5000&search=${addTrackData.search}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      console.log("Result : ", getArtistData);
      if (getArtistData.status === 200) {
        setAllArtistList(getArtistData.data.data.artist);
        // setArtistName(getArtistData.data.data.artist.artist_name);
      }
    } catch (error) {
      console.log(error);
      if (error) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
      }
    }
  };

  const handleDeleteChannel = async () => {

    try {
      const token = localStorage.getItem('token');
      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/channel/delete/${channelId}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      console.log("Test data: ", result);
      if (result.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        navigate("/channel");
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  };

  const handleDeleteTrack = async (id) => {
    console.log("Id : ", id)

    const token = localStorage.getItem('token');
    try {

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/channel/remove-music/${channelId}/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if (result.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setRefresh(refresh + 1);
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  };

  const handleDeleteShow = async (id) => {
    console.log("Id : ", id)

    const token = localStorage.getItem('token');
    try {

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/delete-channel-shows/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if (result.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setRefresh(refresh + 1);
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  };

  const handleEditCoverPictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setEditTrackData({ ...editTrackData, cover_image: file, cover_image_name: file.name });
    } else {
      setEditTrackData({ ...editTrackData, cover_image: null, cover_image_name: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)." }));
    }
  };

  const handleEditTrackFile = (e) => {
    const file = e.target.files[0];
    console.log(file);
    console.log("outside");
    if (file && (file.type === 'audio/mpeg')) {
      console.log("inside");
      const audio = new Audio(URL.createObjectURL(file));
      audio.addEventListener("loadedmetadata", () => {
        setEditTrackData({ ...editTrackData, music_track: file, duration: audio.duration, music_track_name: file.name });
      });
    } else {
      setEditTrackData({ ...editTrackData, music_track: "", duration: 0, music_track_name: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid audio file (mp3)." }));
    }
  };

  const handleEditTrack = async (trackId) => {
    setEditTrack(true);
    setAddTrackModal(false);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseUrl}admin/channel/list-tracks/${channelId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const EditData = response.data.data.find(track => track.id === trackId);
      if (EditData) {
        setEditTrackData({
          title: EditData.title || "",
          description: EditData.description || "",
          status: EditData.status ? "1" : "0",
          language_id: EditData.language_id || "",
          category_id: EditData.category_id || "",
          track_duration: EditData.track_duration || "",
          channel_id: EditData.channel_id,
          id: EditData.id,
          cover_image_name: EditData.cover_image ? EditData.cover_image.split('/').pop() : "",
          music_track_name: EditData.music_track ? EditData.music_track.split('/').pop() : ""
        });
      }
    } catch (error) {
      console.error("Error fetching track data:", error);
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
    }
  };

  const handleEditTrackSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");
    const formDataSet = new FormData();

    // Append data to FormData
    formDataSet.append('title', editTrackData.title);
    formDataSet.append('description', editTrackData.description);
    formDataSet.append('status', editTrackData.status);
    formDataSet.append('language_id', editTrackData.language_id);
    formDataSet.append('category_id', editTrackData.category_id);
    formDataSet.append('track_duration', editTrackData.track_duration);
    formDataSet.append('channel_id', editTrackData.channel_id);
    formDataSet.append('id', editTrackData.id);

    if (editTrackData.cover_image_name !== "") {
      formDataSet.append('cover_image', editTrackData.cover_image);
    }
    if (editTrackData.music_track_name !== "") {
      formDataSet.append('music_track', editTrackData.music_track);
    }

    try {
      const response = await axios.post(`${baseUrl}admin/channel/edit-track`, formDataSet, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: response.data.message }));
        setAddTrackModal(false);
        setEditTrack(false);
        setLoading(false);
        setRefresh(refresh + 1);
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! something went wrong" }));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating track:", error);
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! something went wrong" }));
      setLoading(false);
    }
  };

  return (
    <div className={`${Styles.channel}`}>

      {
        deleteChannelDrop && (
          <div className={`${Styles.deleteArtist}`}>
            <div className={`${Styles.innerDeleteArtist}`}>
              <div className={`${Styles.heading}`}>
                <p>Do you really want to delete this channel?</p>
              </div>

              <div className={`${Styles.delete_image}`}>
                <img src={DeleteIllustration} alt="not supported" />
              </div>

              <div className={`${Styles.buttons}`}>
                <button onClick={handleDeleteChannel}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete Channel"}</button>
                <button onClick={() => { setDeleteChannelDrop(false); setDropDownChannelManage({ id: 0, open: false }); }}>Skip & Continue</button>
              </div>
            </div>
          </div>
        )
      }

      {
        deleteChannelTrack && (
          <div className={`${Styles.deleteArtist}`}>
            <div className={`${Styles.innerDeleteArtist}`}>
              <div className={`${Styles.heading}`}>
                <p>Do you really want to delete this track?</p>
              </div>

              <div className={`${Styles.delete_image}`}>
                <img src={DeleteIllustration} alt="not supported" />
              </div>

              <div className={`${Styles.buttons}`}>
                <button onClick={handleDeleteTrack}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete Track"}</button>
                <button onClick={() => { setDeleteChannelTrack(false); setDropDownChannelManage({ id: 0, open: false }); }}>Skip & Continue</button>
              </div>
            </div>
          </div>
        )
      }

      <div className={`${Styles.channel_nav}`}>

        {/* Artist Details */}
        <div className={`${Styles.artist}`}>
          <div className={`${Styles.channel_info}`}>
            <div className={`${Styles.channel_navigate}`}>
              <button onClick={() => navigate("/channel")}>
                <RiArrowLeftSLine /> Back To Channel
              </button>
            </div>

            {/* Name || Publish || Unpublish */}
            <div className={`${Styles.channel_details}`}>
              <div className={`${Styles.channel_name_status}`}>
                <h2>{channelDetails.title}</h2>
                <p>{channelDetails.status}</p>
              </div>
            </div>

            {/* Artist Row Data */}
            <div className={`${Styles.channel_counts}`}>
              <div className={`${Styles.channel_songs}`}>
                <p>Songs</p>
                <IoRemoveOutline />
                <p>{channelDetails.totalTrack}</p>
              </div>
              <div className={`${Styles.part}`}>
                <FaGripLinesVertical />
              </div>
              <div className={`${Styles.channel_songs}`}>
                <p>Playlists</p>
                <IoRemoveOutline />
                <p>{channelDetails.totalPlaylist}</p>
              </div>
            </div>

            {/* Approve Button */}
            <div className={`${Styles.buttons}`}>
              <div className={`${Styles.active}`}>
                {channelDetails.status === "Publish" &&
                  <>
                    <FaUserXmark className={`${Styles.deactiveSvg}`} />
                    <button className={`${Styles.active_btn}`} onClick={() => changeChannelStatus(0)}>Unpublish</button>
                  </>
                }
                {channelDetails.status === "Unpublish" &&
                  <>
                    <FaUserCheck className={`${Styles.activeSvg}`} />
                    <button className={`${Styles.active_btn}`} onClick={() => changeChannelStatus(1)}>Publish</button>
                  </>
                }
              </div>
              <div className={`${Styles.delete}`}>
                <MdDelete className={`${Styles.deleteSvg}`} />
                <button className={`${Styles.delete_btn}`} onClick={() => { setDeleteChannelDrop(!deleteChannelDrop); }}>Delete</button>
              </div>
            </div>
          </div>
          <div className={`${Styles.channel_image}`}>
            <img src={channelDetails.image} alt="not supported" />
          </div>
        </div>

      </div>

      <div className={`${Styles.channel_data_buttons}`}>
        <div className={`${Styles.buttons}`}>
          <button className={channelModule === "track" ? Styles.active : ""} onClick={() => setChannelModule("track")}>Tracks</button>
          <button className={channelModule === "playlist" ? Styles.active : ""} onClick={() => setChannelModule("playlist")}>Playlists</button>
          <button className={channelModule === "show" ? Styles.active : ""} onClick={() => setChannelModule("show")}>Shows</button>
        </div>
      </div>

      {/* Track Table */}
      {
        channelModule === "track" &&
        <div className={`${Styles.channel_track_data}`}>
          {
            addTrackModal === false && editTrack === false &&
            <>
              <div className={`${Styles.search}`}>
                <input type="text" placeholder="Search" value={trackSearch} onChange={(e) => setTrackSearch(e.target.value)} />
                <button onClick={() => setAddTrackModal(true)}> <LuPlusCircle /> Add Track</button>
              </div>
              <div className={`${Styles.channel_data_table}`}>
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Track</th>
                      <th>Play</th>
                      <th>Duration</th>
                      <th>Created Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      channelTracks.length === 0 &&
                      <tr>
                        <td colSpan="7">
                          <div className={`${Styles.dataNotFound}`}>
                            {
                              contentLoader &&
                              <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                            }
                            {
                              !contentLoader &&
                              <p>Track not found</p>
                            }
                          </div>
                        </td>
                      </tr>
                    }
                    {channelTracks.map((data) => (
                      <tr key={data.id}>
                        <td>{data.id}</td>
                        <td className={`${Styles.name_image}`}>
                          <div className={`${Styles.track_image}`}>
                            <img src={data.image_url} alt="Not supported" />
                          </div>
                          <p>{data.title}</p>
                        </td>
                        <td className={`${Styles.play}`}>
                          <div className={`${Styles.play_div}`}>
                            <FaUsers />
                            <p>{data.play_count === null ? "0" : data.play_count}</p>
                          </div>
                        </td>
                        <td className={`${Styles.time}`}>
                          <div className={`${Styles.time_div}`}>
                            <LuClock7 />
                            <p>{convertDuration(data.track_duration)}</p>
                          </div>
                        </td>
                        <td>{new Date(data.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s+/g, '-')}</td>
                        <div className={`${Styles.track_btn}`}>
                          <td className={`${Styles.edit_track}`}><MdEdit onClick={() => handleEditTrack(data.id)} /></td>
                          {/* <td className={`${Styles.delete_track}`}><MdDelete onClick={() => handleDeleteTrack(data.id)} /></td> */}
                          <td className={`${Styles.delete_track}`}><MdDelete onClick={() => { setDeleteChannelTrack(!deleteChannelTrack); }} /></td>
                        </div>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          }

          {/* Add Track */}
          {
            addTrackModal === true &&
            <>
              <div className={`${Styles.add_normal_track}`}>

                <div className={`${Styles.track_heading}`}>
                  <h2>Add Track</h2>
                </div>

                <div className={`${Styles.artistNames}`}>
                  {
                    artistMetaData.map((data) => {
                      return <button onClick={() => handleArtistId(data.id, data.name)}>{data.name} <span><IoCloseCircleOutline /></span></button>
                    })
                  }
                </div>

                <div className={`${Styles.trackAdd}`}>
                  <div className={`${Styles.add_form}`}>
                    <div className={`${Styles.form_grid}`}>
                      <div className={`${Styles.form_field}`}>
                        <label>Title</label>
                        <input type="text" placeholder="Enter Title" value={addTrackData.title} onChange={(e) => { setAddTrackData({ ...addTrackData, title: e.target.value }); artistSearch(); }} name="name" />
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Description</label>
                        <textarea name="" id="" placeholder="Enter Description" value={addTrackData.description} onChange={(e) => { setAddTrackData({ ...addTrackData, description: e.target.value }); artistSearch(); }}></textarea>
                      </div>
                      <div className={`${Styles.form_field} ${Styles.searchData}`}>
                        <label>Search Artist</label>
                        <input type="text" placeholder="Search artist here ..." value={addTrackData.search} onChange={(e) => { setAddTrackData({ ...addTrackData, search: e.target.value }); artistSearch(); }} name="name" />
                        {
                          addTrackData.search !== "" &&
                          <div className={`${Styles.artistListHere}`}>
                            <div className={`${Styles.artistList}`}>
                              <h4>Artist List</h4>
                              <IoMdCloseCircleOutline onClick={() => setAddTrackData({ ...addTrackData, search: "" })} />
                            </div>
                            {
                              allArtistList.map((data) => {
                                return (
                                  <div className={`${Styles.listData}`}>
                                    <input className={`${Styles.checkbox}`} type="checkbox" name="" id="" checked={artistIds.includes(data.id)} onClick={() => handleArtistId(data.id, data.artist_name)} />
                                    <p>{data.artist_name}</p>
                                  </div>
                                )
                              })
                            }
                          </div>
                        }
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Language</label>
                        <select name="language" value={addTrackData.language_id} onChange={(e) => setAddTrackData({ ...addTrackData, language_id: e.target.value })}>
                          <option value="">**** Select Language ****</option>
                          {
                            language.map((data, index) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Category</label>
                        <select name="category" value={addTrackData.category_id} onChange={(e) => setAddTrackData({ ...addTrackData, category_id: e.target.value })}>
                          <option value="">**** Select Category ****</option>
                          {
                            category.map((data, index) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Status</label>
                        <select name="status" value={addTrackData.status} onChange={(e) => setAddTrackData({ ...addTrackData, status: e.target.value })}>
                          <option value="">**** Select Status ****</option>
                          <option value="1">Publish</option>
                          <option value="0">Unpublish</option>
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Track File</label>
                        <div className={`${Styles.customFileInput}`} >
                          <input type="text" placeholder="Choose file..." value={addTrackData.trackFileName} readOnly />
                          <input type="file" accept=".mp3" id="file-input" name="ImageStyle" onChange={handleTrackFile} />
                        </div>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Cover Picture</label>
                        <div className={`${Styles.customFileInput}`}>
                          <input type="text" placeholder="Choose file..." value={addTrackData.coverImageName} readOnly />
                          <input type="file" accept=".png,.jpg" id="file-input" onChange={handleCoverPictureChange} name="ImageStyle" />
                        </div>
                      </div>
                    </div>

                    <div className={`${Styles.buttons}`}>
                      <button className={`${Styles.action}`} onClick={handleAddNormalTrackSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
                      <button className={`${Styles.cancel}`}
                        onClick={() => {
                          setAddTrackModal(false);
                          setLoading(false);
                          setArtistIds("");
                          setAddTrackData({
                            title: "",
                            description: "",
                            language_id: 1,
                            category_id: 1,
                            status: 1,
                            search: "",
                            track_file: null,
                            trackFileName: "",
                            coverImage: null,
                            coverImageName: "",
                            track_duration: 0
                          })
                        }}>
                        Cancel</button>
                    </div>
                  </div>

                </div>
              </div>
            </>
          }

          {/* Edit Track */}
          {
            editTrack === true && addTrackModal === false &&
            <>
              <div className={`${Styles.add_normal_track}`}>

                <div className={`${Styles.track_heading}`}>
                  <h2>Edit Track</h2>
                </div>

                <div className={`${Styles.artistNames}`}>
                  {
                    artistMetaData.map((data) => {
                      return <button onClick={() => handleArtistId(data.id, data.name)}>{data.name} <span><IoCloseCircleOutline /></span></button>
                    })
                  }
                </div>

                <div className={`${Styles.trackAdd}`}>
                  <div className={`${Styles.add_form}`}>
                    <div className={`${Styles.form_grid}`}>
                      <div className={`${Styles.form_field}`}>
                        <label>Title</label>
                        <input type="text" placeholder="Enter Title" value={editTrackData.title} onChange={(e) => { setEditTrackData({ ...editTrackData, title: e.target.value }); artistSearch(); }} name="name" />
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Description</label>
                        <textarea name="" id="" placeholder="Enter Description" value={editTrackData.description} onChange={(e) => { setEditTrackData({ ...editTrackData, description: e.target.value }); artistSearch(); }}></textarea>
                      </div>
                      <div className={`${Styles.form_field} ${Styles.searchData}`}>
                        <label>Search Artist</label>
                        <input type="text" placeholder="Search artist here ..." value={editTrackData.search} onChange={(e) => { setEditTrackData({ ...editTrackData, search: e.target.value }); artistSearch(); }} name="name" />
                        {
                          editTrackData.search !== "" &&
                          <div className={`${Styles.artistListHere}`}>
                            <div className={`${Styles.artistList}`}>
                              <h4>Artist List</h4>
                              <IoMdCloseCircleOutline onClick={() => setEditTrackData({ ...editTrackData, search: "" })} />
                            </div>
                            {
                              allArtistList.map((data) => {
                                return (
                                  <div className={`${Styles.listData}`}>
                                    <input className={`${Styles.checkbox}`} type="checkbox" name="" id="" checked={artistIds.includes(data.id)} onClick={() => handleArtistId(data.id, data.artist_name)} />
                                    <p>{data.artist_name}</p>
                                  </div>
                                )
                              })
                            }
                          </div>
                        }
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Language</label>
                        <select name="language" value={editTrackData.language_id} onChange={(e) => setEditTrackData({ ...editTrackData, language_id: e.target.value })}>
                          <option value="">**** Select Language ****</option>
                          {
                            language.map((data, index) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Category</label>
                        <select name="category" value={editTrackData.category_id} onChange={(e) => setEditTrackData({ ...editTrackData, category_id: e.target.value })}>
                          <option value="">**** Select Category ****</option>
                          {
                            category.map((data, index) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Status</label>
                        <select name="status" value={editTrackData.status} onChange={(e) => setEditTrackData({ ...editTrackData, status: e.target.value })}>
                          <option value="">**** Select Status ****</option>
                          <option value="1">Publish</option>
                          <option value="0">Unpublish</option>
                        </select>
                      </div>
                      {/* <div className={`${Styles.form_field}`}>
                        <label>Track File</label>
                        <div className={`${Styles.customFileInput}`} >
                          <input type="text" placeholder="Choose file..." value={editTrackData.music_track_name} readOnly />
                          <input type="file" accept=".mp3" id="file-input" name="ImageStyle" onChange={handleEditTrackFile} />
                        </div>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Cover Picture</label>
                        <div className={`${Styles.customFileInput}`}>
                          <input type="text" placeholder="Choose file..." value={editTrackData.cover_image_name} />
                          <input type="file" accept=".png,.jpg" id="file-input" onChange={handleEditCoverPictureChange} name="ImageStyle" />
                        </div>
                      </div> */}
                    </div>

                    <div className={`${Styles.buttons}`}>
                      <button className={`${Styles.action}`} onClick={handleEditTrackSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
                      <button className={`${Styles.cancel}`}
                        onClick={() => {
                          // setAddTrackModal(false);
                          setEditTrack(false);
                          setLoading(false);
                          setArtistIds("");
                          setEditTrackData({
                            title: "",
                            description: "",
                            language_id: 1,
                            category_id: 1,
                            status: 1,
                            search: "",
                            music_track: null,
                            music_track_name: "",
                            cover_image: null,
                            cover_image_name: "",
                            track_duration: 0
                          })
                        }}>
                        Cancel</button>
                    </div>
                  </div>

                </div>
              </div>
            </>
          }
        </div>
      }

      {/* Playlist Card */}
      {
        channelModule === "playlist" &&
        <div className={`${Styles.channel_playlist_data}`}>
          {
            addPlaylistModal === false &&
            <>
              <div className={`${Styles.search}`}>
                <input type="text" placeholder="Search" value={playlistSearch} onChange={(e) => setPlaylistSearch(e.target.value)} />
                <button onClick={() => setAddPlaylistModal(true)}> <LuPlusCircle /> Create Playlist</button>
              </div>
              <div className={`${Styles.channel_playlist_list}`}>
                {
                  channelPlaylist.length === 0 &&
                  <div className={`${Styles.dataNotFound}`}> Data Not Found </div>
                }
                {
                  channelPlaylist.map((data) => (
                    <div className={`${Styles.playlistCard}`} onClick={() => navigate(`/channel/${channelId}/playlistView/${data.id}`)}>
                      <div className={`${Styles.image}`}>
                        <img src={data.cover_image} alt="not supported" />
                      </div>
                      <div className={`${Styles.playlist_details}`}>
                        <h3>{data.title}</h3>
                        <p><span>{data.channel_playlist_tracks?.length}</span> Songs</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </>
          }

          {/* Add Playlist */}
          {
            addPlaylistModal === true &&
            <>
              <div className={`${Styles.add_normal_playlist}`}>

                <div className={`${Styles.playlist_heading}`}>
                  <h2>Add Playlist</h2>
                </div>

                <div className={`${Styles.playlistAdd}`}>
                  <div className={`${Styles.add_form}`}>
                    <div className={`${Styles.form_grid}`}>
                      <div className={`${Styles.form_field}`}>
                        <label>Title</label>
                        <input type="text" placeholder="Enter Title" value={addPlaylistData.title} onChange={(e) => { setAddPlaylistData({ ...addPlaylistData, title: e.target.value }); }} name="name" />
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Description</label>
                        <textarea name="" id="" placeholder="Enter Description" value={addPlaylistData.description} onChange={(e) => { setAddPlaylistData({ ...addPlaylistData, description: e.target.value }); }}></textarea>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Category</label>
                        <select name="category" value={addPlaylistData.category_id} onChange={(e) => setAddPlaylistData({ ...addPlaylistData, category_id: e.target.value })}>
                          <option value="">**** Select Category ****</option>
                          {
                            category.map((data, index) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Language</label>
                        <select name="language" value={addPlaylistData.language_id} onChange={(e) => setAddPlaylistData({ ...addPlaylistData, language_id: e.target.value })}>
                          <option value="">**** Select Category ****</option>
                          {
                            language.map((data, index) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Status</label>
                        <select name="status" value={addPlaylistData.status} onChange={(e) => setAddPlaylistData({ ...addPlaylistData, status: e.target.value })}>
                          <option value="1">Publish</option>
                          <option value="0">Unpublish</option>
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Release Date</label>
                        <input type="date" name="release_date" onChange={(e) => setAddPlaylistData({ ...addPlaylistData, release_date: e.target.value })} />
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Profile Picture</label>
                        <div className={`${Styles.customFileInput}`}>
                          <input type="text" placeholder="Choose file..." value={addPlaylistData.playlistProfileImageName} readOnly />
                          <input type="file" accept=".png,.jpg" id="file-input" onChange={handlePlaylistProfilePictureChange} name="ProfileImageStyle" />
                        </div>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Cover Picture</label>
                        <div className={`${Styles.customFileInput}`}>
                          <input type="text" placeholder="Choose file..." value={addPlaylistData.playlistCoverImageName} readOnly />
                          <input type="file" accept=".png,.jpg" id="file-input" onChange={handlePlaylistCoverPictureChange} name="coverImageStyle" />
                        </div>
                      </div>
                    </div>

                    <div className={`${Styles.buttons}`}>
                      <button className={`${Styles.action}`} onClick={handleAddNormalPlaylistSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
                      <button className={`${Styles.cancel}`}
                        onClick={() => {
                          setAddPlaylistModal(false);
                          setLoading(false);
                          setAddPlaylistData({
                            title: "",
                            description: "",
                            status: 1,
                            search: "",
                            playlistCoverImage: null,
                            playlistCoverImageName: "",
                            playlistProfileImage: null,
                            playlistProfileImageName: ""
                          })
                        }}>
                        Cancel</button>
                    </div>
                  </div>

                </div>
              </div>
            </>
          }
        </div>
      }

      {/* Show Table */}
      {
        channelModule === "show" &&
        <div className={`${Styles.channel_show_data}`}>
          {
            addShowModal === false &&
            <>
              <div className={`${Styles.search}`}>
                <input type="text" placeholder="Search" value={showSearch} onChange={(e) => setShowSearch(e.target.value)} />
                <button onClick={() => setAddShowModal(true)}> <LuPlusCircle /> Create Show</button>
              </div>
              <div className={`${Styles.channel_show_list}`}>
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Track</th>
                      <th>Play</th>
                      <th>Duration</th>
                      <th>Created Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      channelShow.length === 0 &&
                      <tr><td colSpan="7"><div className={`${Styles.dataNotFound}`}>Track Not Found</div></td></tr>
                    }
                    {channelShow.map((data) => (
                      <tr key={data.id}>
                        <td>{data.id}</td>
                        <td className={`${Styles.name_image}`}>
                          <div className={`${Styles.show_image}`}>
                            <img src={data.image_url} alt="Not supported" />
                          </div>
                          <p>{data.title}</p>
                        </td>
                        <td className={`${Styles.play}`}>
                          <div className={`${Styles.play_div}`}>
                            <FaUsers />
                            <p>{data.play_count === null ? "0" : data.play_count}</p>
                          </div>
                        </td>
                        <td className={`${Styles.time}`}>
                          <div className={`${Styles.time_div}`}>
                            <LuClock7 />
                            <p>{convertDuration(data.track_duration)}</p>
                          </div>
                        </td>
                        <td>{new Date(data.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s+/g, '-')}</td>
                        <td className={`${Styles.delete_show}`}><MdDelete onClick={() => handleDeleteShow(data.id)} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          }

          {/* Add Show */}
          {
            addShowModal === true &&
            <>
              <div className={`${Styles.add_normal_show}`}>

                <div className={`${Styles.show_heading}`}>
                  <h2>Add Show</h2>
                </div>

                <div className={`${Styles.showAdd}`}>
                  <div className={`${Styles.add_form}`}>
                    <div className={`${Styles.form_grid}`}>
                      <div className={`${Styles.form_field}`}>
                        <label>Title</label>
                        <input type="text" placeholder="Enter Title" value={addShowData.title} onChange={(e) => { setAddShowData({ ...addShowData, title: e.target.value }); }} name="name" />
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Description</label>
                        <textarea name="" id="" placeholder="Enter Description" value={addShowData.description} onChange={(e) => { setAddShowData({ ...addShowData, description: e.target.value }); }}></textarea>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Language</label>
                        <select name="language" value={addShowData.language_id} onChange={(e) => setAddShowData({ ...addShowData, language_id: e.target.value })}>
                          <option value="">**** Select Category ****</option>
                          {
                            language.map((data, index) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Status</label>
                        <select name="status" value={addShowData.status} onChange={(e) => setAddShowData({ ...addShowData, status: e.target.value })}>
                          <option value="1">Publish</option>
                          <option value="0">Unpublish</option>
                        </select>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Show Picture</label>
                        <div className={`${Styles.customFileInput}`}>
                          <input type="text" placeholder="Choose file..." value={addShowData.shows_image_name} readOnly />
                          <input type="file" accept=".png,.jpg" id="file-input" onChange={handleShowProfilePictureChange} name="ProfileImageStyle" />
                        </div>
                      </div>
                      <div className={`${Styles.form_field}`}>
                        <label>Track File</label>
                        <div className={`${Styles.customFileInput}`} >
                          <input type="text" placeholder="Choose file..." value={addShowData.shows_audio_video_name} readOnly />
                          <input type="file" accept=".mp3" id="file-input" name="ImageStyle" onChange={handleShowFile} />
                        </div>
                      </div>
                    </div>

                    <div className={`${Styles.buttons}`}>
                      <button className={`${Styles.action}`} onClick={handleAddNormalShowSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
                      <button className={`${Styles.cancel}`}
                        onClick={() => {
                          setAddShowModal(false);
                          setLoading(false);
                          setAddShowData({
                            title: "",
                            description: "",
                            status: 1,
                            type: "audio",
                            show_type: "special",
                            shows_image: null,
                            shows_image_name: "",
                            shows_audio_video: null,
                            shows_audio_video_name: "",
                            track_duration: 0
                            // search: "",
                            // playlistCoverImage: null,
                            // playlistCoverImageName: "",
                            // playlistProfileImage: null,
                            // playlistProfileImageName: ""
                          })
                        }}>
                        Cancel</button>
                    </div>
                  </div>

                </div>
              </div>
            </>
          }
        </div>
      }

    </div>
  )
}

export default ChannelView
